import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import nurseService from "../../utils/api/v1/nurseService";

// typings
import { NurseName } from "../../utils/interfaces/Nurse";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import LoadingError from "../../components/Loaders/LoadingError";
import Flex from "../../components/Containers/Flex";
import NurseCard from "../../components/Nurses/NurseCard";
import Button from "@material-ui/core/Button";
import CardContainer from "../../components/Containers/CardContainer";
import { Grid, List, ListItem, ListItemText, Paper, TextField, Typography } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import communesAPI from "../../utils/api/v2/communes";
import _ from "lodash";
import NurseProfileSummary from "./[id]/profile-summary";
import useHTManagementState from "../../stores/HTManagementStore";

const WorkersList = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [nursesLoading, setNursesLoading] = useState<boolean>(false);

  const [fetchedInactive, setFetchedInactive] = useState<boolean>(false);
  const [showingActive, setShowingActive] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [workers, setWorkers] = useState<NurseName[] | null>([]);
  const [inactiveWorkers, setInactiveWorkers] = useState<NurseName[] | null>([]);
  const [communes, setCommunes] = useState<any>([]);
  const [selectedCommune, setSelectedCommune] = useState<string>();
  const {selectedNurse} = useHTManagementState();

  const [visibleWorkers, setVisibleWorkers] = useState<NurseName[]>([]);

  const handlerOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    const currentWorkers = showingActive ? workers : inactiveWorkers;
    if (value === "") {
      setVisibleWorkers(currentWorkers);
      return;
    }
    const filteredWorkers = currentWorkers.filter((worker: NurseName) => {
      const targetProp = `${worker.names} ${worker.last_names}`;
      return targetProp
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(value);
    });
    setVisibleWorkers(filteredWorkers);
  };

  const fetchCommunes = async (): Promise<void> => {
    setLoading(true);

    try {
      const req = await communesAPI.get();
      console.log(req.data);
      const grouped = _.groupBy(req.data, 'region_group');
      //console.log(grouped.sort((a, b) => (a.name > b.name ? 1 : -1)));

      setCommunes(grouped);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  const fetchActiveData = async (): Promise<void> => {
    setLoading(true);

    try {
      const req = await nurseService.fetchActiveNurses();
      setWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  const fetchByCommune = async (): Promise<void> => {
    setNursesLoading(true);
    setWorkers(null);
    setVisibleWorkers(null)
    try {
      const req = await nurseService.fetchByCommune(selectedCommune);
      setWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setNursesLoading(false);
  };


  const fetchInactiveData = async (): Promise<void> => {
    setLoading(true);

    try {
      const req = await nurseService.fetchInactiveNurses();
      setFetchedInactive(true);
      setInactiveWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchCommunes();
  }, []);



  useEffect(() => {
    if (!showingActive) {
      if (!fetchedInactive) {
        fetchInactiveData();
      } else {
        setVisibleWorkers(inactiveWorkers);
      }
    } else {
      setVisibleWorkers(workers);
    }
  }, [showingActive]);

  useEffect(() => {
    if (selectedCommune) {
      fetchByCommune()
    }
  }, [selectedCommune])

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <>
          <Flex align="center" justify="space-between" margin="0 0 2rem 0">
            <Grid container>
              <Grid item xs={12}>
                <Typography>HT Management {">"} Por comunas</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={12} sm={5} md={3} lg={3}>
                    <Link
                      to="/health-team/new"
                      style={{ textDecoration: "none" }}
                    >
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Flex>
          <Flex>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <Paper style={{ maxHeight: 500, minHeight: 500, overflowY: 'scroll', padding:'7px' }}>
                  <List style={{ maxHeight: '100%'}} >
                    {Object.keys(communes)?.sort().map((region) => (
                      <>
                        <Typography>{region}</Typography>
                        {communes[region].sort((a, b) => (a.name > b.name ? 1 : -1)).map((item) => (
                          <ListItem selected={item.name == selectedCommune} button key={item.name} onClick={() => setSelectedCommune(item.name)} dense>
                            <ListItemText primary={item.name} />
                          </ListItem>
                        )
                        )}
                      </>
                    )
                    )}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper style={{ maxHeight: 500, minHeight: 500, overflow: 'auto', padding:'7px' }}>
                  <List>
                    {visibleWorkers?.map((worker: NurseName, index: number) => (
                      <NurseCard
                        variant="small"
                        key={index}
                        names={`${worker.names} ${worker.last_names}`}
                        picture={worker.profile_picture}
                        id={worker.id}
                        selectedNurseId = {selectedNurse}
                        services_category={worker.services_category}
                      />
                    ))}
                    {visibleWorkers?.length == 0 && "No hay HT en esta comuna"}
                    {nursesLoading && <>Cargando</>}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ maxHeight: 500, minHeight: 500, overflow: 'auto', padding:'7px' }}>
                  { selectedNurse && selectedCommune &&
                    <NurseProfileSummary id={selectedNurse} />
                  }
                </Paper>
              </Grid>
            </Grid>
          </Flex>



        </>
      )}
    </PrivateRoute>
  );
};

export default WorkersList;
