import { AxiosResponse } from "axios";
import api from "../api";

class CommunesAPI {
  get(): Promise<AxiosResponse<any>> {
    return api.get(`v2/communes/`);
  }
}

const communesAPI= new CommunesAPI();
export default communesAPI;
